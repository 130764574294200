import { type BaseData, MainSubStructureModel, RenderByPermission, request } from '@/utils';
import { Button, type FormInstance, message } from 'antd';
import dayjs from 'dayjs';
import { action, observable } from 'mobx';
import { EditorModel } from './modal/editorModel';

export default class ServiceModel {
  @observable public editorStore: EditorModel = new EditorModel(this);
  constructor() {
    this.mainSubStructureModel.onQuery();
  }

  public accessor mainSubStructureModel: MainSubStructureModel = new MainSubStructureModel({
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    grid: {
      rowHeight: 90,
      columns: [
        {
          key: 'operation',
          name: '操作',
          formatter: ({ row }) => {
            const pageId = new URLSearchParams(window.location.search)?.get('pageId');
            return (
              <div className="operation">
                <RenderByPermission permissionId={`${pageId}_3`}>
                  <Button
                    type="link"
                    onClick={() => {
                      this.editorStore.onShow(row);
                    }}
                  >
                    编辑
                  </Button>
                </RenderByPermission>
              </div>
            );
          },
        },
        {
          key: 'name',
          name: '服务名称',
        },
        {
          key: 'source',
          name: '终端',
          formatter: ({ row }) => {
            return row.source ? (row.source === 1 ? '主播端' : '雇主端') : '';
          },
        },
        {
          key: 'intro',
          name: '服务说明',
        },
        {
          key: 'auditStatus',
          name: '更新人',
          formatter: ({ row }) => {
            const { updateBy, updateByMobile, updateByName } = row;
            if (!updateBy) {
              return '';
            }
            return (
              <div className="flex items-center cursor-pointer h-full gap-2">
                <div className="text-12 leading-[18px]">
                  <div className="flex items-center">用户昵称：{updateByName}</div>
                  <div>ID：{updateBy}</div>
                  <div>手机号：{updateByMobile}</div>
                </div>
              </div>
            );
          },
        },
        {
          key: 'updateTime',
          name: '更新时间',
          formatter: ({ row }) => {
            if (row.updateTime) {
              return dayjs(row.updateTime).format('YYYY-MM-DD HH:mm:ss');
            }
            return null;
          },
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: false,
      showEmpty: true,
      showPager: true,
      showPagination: true,
      showGridOrderNo: false,
      showSelectedTotal: false,
    },
    hiddenSubTable: true,
    api: {
      onQuery: (params) => {
        const { ...rest } = params;
        const postParams = {
          ...rest,
        };
        postParams.filterParams = undefined;

        return request<BaseData<any>>({
          url: '/pbb/platform/sys/service/project/list',
          method: 'post',
          data: {
            ...postParams,
          },
        });
      },
    },
  });
}
