import { type BaseData, request } from '@/utils';
import { type FormInstance, message } from 'antd';
import { action, observable } from 'mobx';

export class EditorModel {
  @observable public visible = false;
  @observable public parent = null;

  @observable public ref: FormInstance;
  @observable public id: number;
  @observable public saveLoading = false;

  constructor(parent) {
    this.parent = parent;
  }

  @action public setRef = (ref) => {
    this.ref = ref;
  };

  @action public onShow = (row) => {
    this.visible = true;
    this.id = row.id;
    setTimeout(() => {
      this.ref.setFieldsValue({
        ...row,
      });
    }, 0);
  };

  @action public onSubmit = async () => {
    const isValidated = await this.ref.validateFields();
    if (!isValidated) {
      return;
    }
    this.saveLoading = true;
    const values = this.ref.getFieldsValue();
    const postParams = { ...values };
    request<BaseData<any>>({
      url: '/pbb/platform/sys/service/project/update',
      method: 'POST',
      data: {
        id: this.id,
        ...postParams,
      },
    })
      .then((result) => {
        message.success('操作成功');
        this.parent.mainSubStructureModel.onQuery();
        this.onClose();
      })
      .finally(() => {
        this.saveLoading = false;
      });
  };
  @action public onClose = () => {
    this.ref.resetFields();
    this.visible = false;
  };
}
