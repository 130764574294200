import { MainSubStructure } from '@/utils';
import { Button, Form, InputNumber } from 'antd';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import Editor from './modal/editor';
import ServiceModel from './model';
const ServiceManagement = () => {
  const store = useMemo(() => new ServiceModel(), []);
  const { mainSubStructureModel, editorStore } = store;

  return (
    <div className="h-screen">
      <MainSubStructure store={mainSubStructureModel} />
      <Editor store={editorStore} />
    </div>
  );
};

export default observer(ServiceManagement);
