import { Button, Form, Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import type { EditorModel } from './editorModel';

const Editor = ({ store }: { store: EditorModel }) => {
  const { visible, onClose, setRef, onSubmit } = store;

  return (
    <Modal
      title="编辑服务"
      centered={true}
      closable={true}
      open={visible}
      onCancel={onClose}
      width={383}
      onOk={onSubmit}
    >
      <Form
        ref={setRef}
        autoComplete="off"
      >
        <Form.Item
          label="服务名称"
          name="name"
          rules={[
            {
              required: true,
              message: '请填写服务名称',
            },
          ]}
        >
          <Input
            placeholder="请输入服务名称"
            maxLength={20}
          />
        </Form.Item>
        <Form.Item
          label="服务说明"
          name="intro"
          className="ml-2"
        >
          <Input.TextArea
            maxLength={200}
            allowClear={true}
            autoSize={{ minRows: 4, maxRows: 4 }}
            showCount={true}
            placeholder="请输入服务说明"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default observer(Editor);
